import DatePicker from './datepicker';


Noodl.defineModule({
    reactNodes: [
    	DatePicker
    ],
    nodes:[
    ],
    setup() {
    	//this is called once on startup
    }
});